<template>
  <div class="property">
    <div v-if="title" class="title">
      {{ title }}
    </div>
    <textarea
      ref="input"
      :readonly="readOnly"
      type="text"
      :placeholder="title"
      v-model="areaData"
      v-on:keyup="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: 'TextField',
  data() {
    return {
      areaData: this.value,
    };
  },
  props: {
    // initValue: String,
    title: String,
    value: String,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.areaData);
    },
  },
  watch: {
    value() {
      this.areaData = this.value;
    },
  },
};
</script>
<style scoped lang="scss">
.property {
  width: 100%;
  textarea {
    padding: 6px 6px;
    font-size: 13px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #23282c;
    border-radius: 3px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    color: var(--secondary-font-color);
  }

  .title {
    font-size: 10px;
    opacity: 0.8;
    text-transform: uppercase;
  }
}
</style>
