<template>
  <div class="command-table">
    <table>
      <thead>
        <tr>
          <th>Enabled on first boot</th>
          <th>Script</th>
          <th>Requires Reboot</th>
          <th v-show="standardScripts.showActions">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="script in standardScripts.active" :key="script._id">
          <td><i class="uil uil-check-circle green" /></td>
          <td>{{ script.command.description }}</td>
          <td>{{ script.command.reboot ? 'Yes' : 'No' }}</td>
          <td v-show="standardScripts.showActions">
            <a class="link" @click="disableStandardScript(script._id)">Disable</a>
          </td>
        </tr>

        <tr v-for="script in standardScripts.inactive" :key="script._id">
          <td><i class="uil uil-times-circle red" /></td>
          <td>{{ script.description }}</td>
          <td>{{ script.reboot ? 'Yes' : 'No' }}</td>
          <td v-show="standardScripts.showActions">
            <a class="link" @click="enableScript(script._id)">Enable</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Utils from '@/utils';

export default {
  name: 'StandardScripts',
  data() {
    return {
      standardScripts: {
        active: [],
        inactive: [],
        showActions: false,
      },
    };
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async getStandardScripts() {
      const response = await this.$utils.http().get('/api/v1/standard-scripts');
      this.standardScripts.active = response.data.active;
      this.standardScripts.inactive = response.data.inactive;
    },

    async enableScript(scriptId) {
      await this.$utils.http().post('/api/v1/standard-scripts', {
        script: scriptId,
      });
      this.getStandardScripts();
    },

    async disableStandardScript(standardScriptId) {
      await this.$utils.http().delete(`/api/v1/standard-scripts/${standardScriptId}`);
      this.getStandardScripts();
    },
  },
  mounted() {
    this.getStandardScripts();
    if (this.userCan('edit', 'script')) {
      this.standardScripts.showActions = true;
    }
  },
};
</script>

<style scoped lang="scss">
.command-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.command-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: rgba(255, 255, 255, 0.04);
  }

  th {
    font-weight: normal;
  }
}
</style>
