<template>
  <div class="settings">
    <div class="container-fluid">
      <h1>Settings</h1>
      <grid>
        <grid-col v-if="userCan('view', 'script')" size="6">
          <card title="Standard Scripts">
            <div class="property">
              <p>
                All newly approved devices will automatically get the selected scripts to run. This
                will make sure that all devices have the same setup.
              </p>
            </div>
            <div>
              <StandardScripts />
            </div>
          </card>
          <install-script-display :organizationId="organizationId" />
        </grid-col>
        <grid-col size="6" v-if="userCan('view', 'tag')">
          <card title="Tags">
            <p>
              This is the list of tags that are available to the user when editing or approving a
              device. Only these tags are valid and removed tags will also be removed for the
              devices that have these tags. Please note that tags will be removed after you click
              "Save tags". Every tag that you delete is checked to see how many devices are using
              it.
            </p>
            <vue-tags-input
              class="tags-input"
              v-model="tag"
              :tags="tags"
              :autocomplete-min-length="0"
              :autocomplete-items="filteredTags"
              @tags-changed="(newTags) => (tags = newTags)"
              @before-deleting-tag="beforeDeleteTag"
            />
            <div class="property" v-if="userCan('edit', 'tag')">
              <div v-if="tagsToDelete.length">
                Tags to remove: <span v-html="tagsToDelete.join(', ')"></span> [<a
                  @click="undoDeletedTags"
                  class="link"
                  >undo</a
                >]
              </div>
              <base-button title="Save Tags" v-on:click="saveTags" type="THEMED" iconType="SAVE" />
            </div>
          </card>

          <card title="SSL Certificate Tag">
            <p>
              Select a tag to be attached to devices that allows SSL certificates to be deployed to
              this device
            </p>

            <select v-model="ssltag" name="ssltag" class="input-select" v-if="tags">
              <option :value="null">Select a tag</option>
              <option v-for="tag in tags" :key="tag.text" :value="tag.text">
                {{ tag.text }}
              </option>
            </select>

            <div class="property" v-if="tags && ssltag">
              <base-button
                title="Save SSL tag"
                v-on:click="saveSSLTag"
                type="THEMED"
                iconType="SAVE"
              />
            </div>
          </card>
        </grid-col>
      </grid>
    </div>
  </div>
</template>
<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Utils from '@/utils';
import Card from '../components/Card/Card.vue';
import StandardScripts from '../components/Table/StandardScripts.vue';
import InstallScriptDisplay from '../components/InstallScript/InstallScriptDisplay.vue';
import GridCol from '../components/Grid/GridCol.vue';
import Grid from '../components/Grid/Grid.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';

export default {
  name: 'settings',
  components: {
    Card,
    StandardScripts,
    VueTagsInput,
    InstallScriptDisplay,
    Grid,
    GridCol,
    BaseButton,
  },
  data() {
    return {
      tag: '',
      tags: [],
      autocompleteItems: [],
      ssltag: null,
      organization: null,
      tagsToDelete: [],
    };
  },
  methods: {
    async beforeDeleteTag(obj) {
      const response = await this.$utils.http().get(`/api/v1/tags/${obj.tag.text}/devices`);

      if (response && response.data && response.data.devices) {
        this.tagsToDelete.push(
          `<span class="warning">${obj.tag.text} (${response.data.devices})</span>`,
        );
      } else {
        this.tagsToDelete.push(`<span class="success">${obj.tag.text}</span>`);
      }

      obj.deleteTag();
    },
    async undoDeletedTags() {
      this.getTags();
    },
    async getTags() {
      const response = await this.$utils.http().get('/api/v1/tags');
      const mappedTags = response.data.sort().map((e) => ({
        text: e,
      }));

      this.tags = mappedTags;
      this.autocompleteItems = mappedTags;
      this.tagsToDelete = [];
    },
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async saveTags() {
      try {
        const response = await this.$utils.http().post('/api/v1/tags', {
          tags: this.tags.map((e) => e.text),
        });

        if (response.data.devicesModified) {
          this.$noty.success(
            `The tags were saved! ${response.data.devicesModified} device(s) were modified.`,
            { timeout: 2500 },
          );
        } else {
          this.$noty.success('The tags were saved', { timeout: 1500 });
        }
        this.getTags();
      } catch (e) {
        this.$noty.error(`Something went wrong ${e.response.data.message}`);
      }
    },
    isAdmin() {
      return Utils.isAdmin();
    },
    async getOrganization() {
      this.organization = await Utils.getOrganization();
      this.ssltag = this.organization?.sslCertificateTag;
    },
    async saveSSLTag() {
      const url = '/api/v1/organizations/ssltag';
      const response = await Utils.post(url, { tag: this.ssltag }, this);
      if (response.result) {
        this.$noty.success('The tag was saved', { timeout: 1500 });
      } else {
        this.$noty.error('The tag could not be saved');
      }
    },
  },
  mounted() {
    this.getTags();
    this.getOrganization();
  },
  computed: {
    organizationId() {
      if (this.organization?._id) {
        return this.organization._id;
      }
      const user = Utils.getUser();
      return user.organization;
    },
    filteredTags() {
      return this.autocompleteItems.filter(
        (i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1,
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
