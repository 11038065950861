<template>
  <card title="Install">
    <div class="content">
      <text-field :value="url" :readOnly="true" ref="urlField" />
    </div>
    <div class="actions">
      <base-button type="THEMED" v-on:click="copyUrl">Copy<i class="uil uil-copy" /></base-button>
      <o-s-logo operating-system="LINUX" />
      <value-switch class="os-switch" @changed="changeOs" :useTwoColored="true" :enabled="false" />
      <o-s-logo operating-system="WINDOWS" />
    </div>
  </card>
</template>

<script>
import TextField from '../Property/TextField/TextField.vue';
import ValueSwitch from '../Switch/ValueSwitch.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import OSLogo from '../OSLogo/OSLogo.vue';
import Card from '../Card/Card.vue';

export default {
  name: 'InstallScriptDisplay',
  components: {
    TextField,
    ValueSwitch,
    Card,
    BaseButton,
    OSLogo,
  },
  props: ['organizationId'],
  data() {
    return {
      os: 'linux',
      orgId: null,
    };
  },
  methods: {
    copyUrl() {
      this.$refs.urlField.$refs.input.select();
      document.execCommand('copy');
      this.$noty.success('URL copied to clipboard!');
    },
    changeOs() {
      if (this.os === 'linux') {
        this.os = 'windows';
      } else {
        this.os = 'linux';
      }
    },
  },
  computed: {
    url() {
      const urlEndpoint = `${window.location.protocol}//${window.location.hostname}/api/v1/install/${this.os}/${this.organizationId}`;
      if (this.os === 'linux') {
        return `curl -s ${urlEndpoint} | bash -s`;
      }
      return `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12; iwr -useb ${urlEndpoint} | iex`;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  align-items: center;
}
.actions {
  display: flex;
  align-items: center;
}

button {
  margin-right: 16px;
  height: 28px;
}

.button.small {
  padding: 5px;
}

.os-switch {
  margin: 0 6px;
}

img {
  max-width: 13px;
}
</style>
<style lang="scss">
textarea {
  min-height: 60px;
}
</style>
